<template>
  <div class="text-gray-200 dark:text-white rounded dark:bg-gray-800 bg-blue-900 p-10 w-full">

    <div class="flex">
      <g-emoji>📄</g-emoji>
      <span class=" pl-2 pr-5 text-gray-400"> Договор </span>
      {{user.uf_contract}}
    </div>
    <div>
      <g-emoji>💰</g-emoji>
      <span class=" pl-2 pr-5 text-gray-400">УНП: </span>
       {{user.uf_unp}}
    </div>
    <div>
      <g-emoji>🏢</g-emoji>
      <span class=" pl-2 pr-5 text-gray-400">Юридический адрес: </span>
       {{user.uf_adress}}
    </div>
    <div>
      <g-emoji>🗺</g-emoji>
      <span class=" pl-2 pr-5 text-gray-400">Страна регистрации: </span>
      {{user.uf_org_country}}
    </div>
    <div>
      <g-emoji>✉️</g-emoji>
      <span class=" pl-2 pr-5 text-gray-400">Email: </span>
      {{user.uf_org_email}}
    </div>
    <div>
      <g-emoji>☎️</g-emoji>
      <span class=" pl-2 pr-5 text-gray-400">Телефон/Факс: </span>
      {{user.uf_org_phone}}
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Info",
  computed: {
    ...mapGetters({
      countries: 'catalogs/countries'
    })
  },
  props:{
    user:{
      required:true
    }
  },
}
</script>

<style scoped>

</style>
