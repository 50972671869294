<template>
    <div v-if="notify" class="bg-yellow-300  basis-full flex-grow rounded p-5 text-black  text-sm ">
        <p>Информируем Вас о необходимости заключения дополнительного соглашения к заключенному договору.
            Оказание услуги будет приостановлено согласно пункта 3.1. договора. </p>
    </div>
</template>

<script>
export default {
    name: "CheckTransportationsAndContracts",
    data:()=> ({
        notify:false
    }),
    created() {
      this.$store.dispatch('clientTrans/checkTransportations').then(res => {
        this.notify = res.data.data
      })
    },
}
</script>

<style scoped>

</style>