<template>
    <ModalWindow class="overflow-auto">
        <div class="modal__content h-5/6 overflow-y-scroll">
            <div class="modal__header">
                <h2 class="text-2xl">Изменения в договоре оказания услуг</h2>
            </div>
            <div class="py-5 ">
                <div>
                    <p class="notification">
                      Уважаемые клиенты!<br><br>
                      С 20.04.2023 внесены изменения и дополнения в Публичную оферту на заключение договора возмездного
                      оказания услуг и Порядок оказания услуг по отслеживанию (мониторингу) транспортных средств, на
                      грузовые помещения (отсеки) которых наложены навигационные устройства (пломбы). Обращаем Ваше
                      внимание, что в порядок оказания услуг добавлена услуга по мониторингу транспортного средства с
                      использованием навигационных устройств (пломб) в рамках эксперимента проводимого в соответствии с
                      требованиями распоряжения Совета ЕЭК от 17.10.2022 № 29 «О проведении РБ и РФ эксперимента по
                      применению навигационных пломб в отношении отдельных категорий товаров, помещенных под
                      таможенную процедуру экспорта»). <br><br>
                      Согласие с новыми изменениями и дополнениями публичной оферты производится путем присоединения
                      Заказчика к настоящему Договору, т. е. посредством принятия (акцепта) Заказчиком
                      условий настоящего Договора. <br><br>
                    </p>
                </div>

                <a :key="'doc-'+idx" v-for="(doc, idx) in documents" class="agree__file" :href="doc.link" target="_blank">
                    <div >
                        <img v-show="dark" class="h-8" src="@/assets/img/icons/document-white.svg">
                        <img  v-show="!dark" class="h-8" src="@/assets/img/icons/document.svg">
                    </div>
                    <span class="mx-4">{{ doc.name }}</span>
                </a>

                <label class="block py-5" v-if="!readonly">
                    <input type="checkbox" v-model="confirm">
                    Ознакомлен, согласен с условиями договора и согласен на перезаключение договора
                </label>
                    <div class="flex justify-end">
                        <button v-if="readonly"  @click="$emit('close')" class="bg-red-600 px-5 py-2.5 rounded text-white ">Закрыть</button>
                        <button v-else :disabled="!confirm" :class="{'bg-green-600':confirm, 'bg-gray-600':!confirm}" @click="submit" class="px-5 py-2.5 rounded text-white ">Прочитано</button>
                    </div>

            </div>
        </div>
    </ModalWindow>
</template>

<script>
import ModalWindow from  '@/components/common/ModalWindow.vue'
import {mapGetters} from "vuex";
export default {
    name: "NotificationModal",
    components:{
        ModalWindow
    },
    props:{
        readonly:{
            type:Boolean,
            default:false
        }
    },
    data:()=> ({
        documents:[],
        confirm:false
    }),
    created() {
        this.getDocuments()
    },
    computed:{
        ...mapGetters({
            dark:'darkTheme'
        }),
        offer(){
           const { link } = this.documents.find(i => i.name.indexOf('оферт') !== -1) || {}
            return link
        }
    },
    methods:{
        getDocuments(){
            this.$store.dispatch('news/getContractDocuments').then(res => {
                this.documents = res.data.data
            })
        },
        submit(){
            this.$store.dispatch('clientNotifications/acceptNotification')
        }
    }
}
</script>

<style scoped>
.modal__content {
    @apply lg:w-1/2 sm:w-full h-5/6  dark:bg-black bg-white dark:text-white text-black  p-10 rounded relative lg:border-l-2
}

.modal__header {
    @apply flex justify-between  w-full
}
.agree__file{
    @apply flex my-3
}
input[type=checkbox]{
    transform: scale(1.2);
}
.notification a{
    @apply text-blue-600
}
</style>