<template>
    <div class="client-page">
        <div class="client-page__wrapper">
            <h1 class="client-page__title">{{ user.uf_name }}</h1>
            <div class="client-page__main-info">
                <div class="client-page__user">
                    <Info :user="user"></Info>
                </div>
                <div class="client-page__balance">
                    <Balance class=""></Balance>
                </div>
            </div>
            <div class="client-page__notification" @click="showNotification">
                Об изменениях в договорах с 20.04.2023
            </div>
            <div class="client-page__menu">
                <router-link tag="div"
                             class="client-page__menu-item"
                             :to="{name:'Client-cars'}"
                             :style="{ backgroundImage:'url('+require('@/assets/img/stock/trucks.jpg')+')' }">
                    <p class="client-page__menu-item-text">Мой транспорт</p>
                </router-link>
                <router-link tag="div" :to="{name:'Transportations'}"
                             class="client-page__menu-item"
                             :style="{ backgroundImage:'url('+require('@/assets/img/stock/client-cars.jpg')+')' }">
                    <p class="client-page__menu-item-text">Мои перевозки</p>
                </router-link>
                <router-link tag="div" :to="{name:'ClientPayments'}"
                             class="client-page__menu-item"
                             :style="{ backgroundImage:'url('+require('@/assets/img/stock/payments.jpg')+')' }">
                    <p class="client-page__menu-item-text">Мои платежи</p>
                </router-link>
              <router-link tag="div" :to="{name:'ClientRegistrations'}"
                           class="client-page__menu-item"
                           :style="{ backgroundImage:'url('+require('@/assets/img/stock/registrations.jpg')+')' }">
                <p class="client-page__menu-item-text">Мои регистрации</p>
              </router-link>
            </div>


            <div class="menu">
                <router-link  :to="{name:'ExportData'}"
                             class="export-button">
                    <span class="pr-4">Экспорт данных</span><i
                    class="fas fa-arrow-right"></i></router-link>
                <div class="contract-warn">
                    Обращаем внимание, что для своевременного и корректного зачисления денежных средств в Личный
                    кабинет, необходимо при оплате услуг в назначении платежа обязательно указывать учетный
                    номер плательщика (УНП), дату и номер долгосрочного договора (пример, УМП/0101-22-00).
                </div>
                <CheckTransportationsAndContracts class="col-span-full"/>
            </div>

        </div>
        <NotificationModal v-if="showNotify" @close="showNotify = false" readonly></NotificationModal>
    </div>

</template>

<script>
import Balance from '../../components/user/client/Balance.vue'
import Info from '../../components/user/client/Info.vue'
import CheckTransportationsAndContracts from '@/components/user/client/CheckTransportationsAndContracts.vue'
import {mapGetters} from "vuex";
import NotificationModal from '@/components/user/NotificationModal'
export default {
    meta: {
        title: 'Клиент Белтаможсервис'
    },
    name: "ClientPage",
    components: {
        Balance, Info, CheckTransportationsAndContracts,NotificationModal
    },
    data:() => ({
        showNotify:false
    }),
    computed: {
        ...mapGetters({
            user: 'auth/user',
        })
    },
    created() {
        this.$store.dispatch('auth/getUser')
    },
    methods:{
        showNotification(){
            this.showNotify = true
        }
    }
}
</script>

<style scoped>
.client-page {
    @apply mx-auto
    sm:my-10
    xl:container
}

.client-page__wrapper {
    @apply xl:mx-10
}
.client-page__title{
    @apply dark:text-white text-gray-800 text-2xl
}
.client-page__main-info{
    @apply flex w-full sm:flex-col lg:flex-row my-5 text-lg
}
.client-page__user{
    @apply mr-5 sm:mb-5
    sm:w-full
    lg:w-1/2  lg:mb-0
    xl:w-3/4
}
.client-page__notification{
    @apply sm:w-full
    sm:mb-5
    rounded
    bg-yellow-300
    text-black
    p-5
    cursor-pointer
}
.client-page__balance{
    @apply
    sm:w-full
    lg:w-1/2
    xl:w-1/4
}
.client-page__menu{
    @apply grid sm:grid-cols-1 lg:grid-cols-3 justify-between gap-5 grid-flow-row
}
.client-page__menu-item {
    @apply  rounded h-52 bg-center cursor-pointer flex items-center text-white
    sm:w-full;
    filter: grayscale(1);
    background-size: 100%;
    transition: all 0.5s ease-in-out !important;
}
.client-page__menu-item:hover {
    background-size: 110%;
}
.client-page__menu-item-text{
    @apply mx-5 text-xl
}
.menu{
    @apply grid grid-cols-12 gap-5 my-5
}
.export-button{
    @apply lg:col-span-2 sm:col-span-full flex items-center justify-center text-white  bg-green-400 rounded px-10 py-5
}
.contract-warn{
    @apply lg:col-span-10 sm:col-span-full flex items-center justify-center  text-sm text-white  bg-red-900 rounded h-full p-5 w-full
}

</style>
