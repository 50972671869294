<template>
<div class="text-white  rounded bg-blue-300 w-full h-full p-10">
  <h3 class="text-2xl font-semibold mb-2">Финансы</h3>
  <div v-if="Object.keys(balance).length">
    <span class="block">Баланс: {{balance.balance}} руб.</span>
    <span class="block">Резерв: {{balance.hold_sum}} руб.</span>
    <span class="block">Обновлено: {{ balance.updated  }}</span>
  </div>
  <div v-else>
      <p class="text-lg">Нет данных</p>
  </div>


</div>
</template>

<script>
import axios from "axios";
export default {
    name: "Balance",
    data:()=>({
        balance:{}
    }),
    created(){
        axios.get('https://declarant.by/rest/user/client/balance')
        .then(res => {
            this.balance =  res.data.data
        })
    }

}
</script>

<style scoped>

</style>
